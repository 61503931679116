<template>
  <footer>
    <div class="container">
      <div class="copyright">
         Copyright © <strong>Jalloul Consulting</strong><br>All Rights Reserved
      </div>
    </div>
  </footer>
</template>


<style scoped>
.copyright {
    text-align: center;
}

footer {
    background: #111;
    padding: 30px 0;
    color: #fff;
}
</style>